@import '~@which/seatbelt/src/styles/styles';

$max-width: 330px;

.section {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: scroll;
  background: #fff;
  z-index: 1005;
  padding-bottom: 104px;

  @include sb-respond-to(medium) {
    display: none;
  }

  @include sb-respond-to(large) {
    display: block;
    position: relative;
    width: $max-width;
    background-color: transparent;
    margin: 50px $sb-spacing-l 0 0;
    overflow: visible;
    padding: 0;
    z-index: 1;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    padding: $sb-spacing-l $sb-spacing-l;

    p {
      max-width: $max-width;
      padding: 0 $sb-spacing-xl;
    }

    @include sb-respond-to(medium-up) {
      padding: $sb-spacing-xl $sb-spacing-l $sb-spacing-l;
    }

    @include sb-respond-to(large) {
      padding: $sb-spacing-s 0 $sb-spacing-s;
    }
  }

  &Content {
    margin-top: 0;
    padding: 0 $sb-spacing-l;

    @include sb-respond-to(large) {
      padding: 0;
    }
  }

  &-ShowFilters {
    display: block;
  }
}
