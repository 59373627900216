@import '~@which/seatbelt/src/styles/styles';

.filterButtonInSortby {
  margin: 0 0 0 $sb-spacing-m;
  flex-basis: 109px;
  flex-grow: 0;

  &:hover {
    cursor: pointer;
  }

  @include sb-respond-to(medium) {
    margin: 0 $sb-spacing-m 0 0;
    order: 1;
  }

  @include sb-respond-to(large) {
    display: none;
  }
}

.filterCount {
  width: 26px;
  height: 26px;
  border-radius: $sb-border-radius-full;
  background: $sb-colour-background-default;
  color: $sb-colour-text-link-default;
  margin: 0 7px 0 -7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterFunnel {
  position: relative;
  top: -1px;
  g {
    fill: $sb-colour-background-default;
  }
}
