@import '~@which/seatbelt/src/styles/styles';

$sortByWidth: 410px;
$max-width: 330px;

.menuContainer {
  position: relative;
  z-index: 1;

  @include sb-respond-to(large) {
    position: relative;
    max-width: $sortByWidth;
    width: 100%;
    height: 50px;
    z-index: 2;
    flex-shrink: 0;
  }
}

.customWrapper {
  @include sb-respond-to(medium-up) {
    position: absolute;
    display: inline-block;
    max-width: $max-width;
    width: 100%;
    right: 0;
  }
}

.sortMenuAlign {
  @include sb-respond-to(medium-up) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }

  span {
    width: 80px;
    display: none;

    @include sb-respond-to(medium-up) {
      display: inline-block;
      position: relative;
      top: 7px;
    }
  }
}

.sortMenuInSortby {
  flex-grow: 1;
  margin-right: 0;
  padding-right: 0;
  max-width: 404px;

  @include sb-respond-to(medium) {
    height: 44px;
    position: relative;
  }

  @include sb-respond-to(medium-up) {
    order: 2;
    margin-right: unset;
  }

  @include sb-respond-to(large) {
    width: 100%;
    position: relative;
    flex: 1 0 auto;
  }

  .dropdownContainer {
    max-width: $max-width;
  }

  .selectedContainer {
    position: relative;
    padding: $sb-spacing-s 0 $sb-spacing-s $sb-spacing-m;

    [class*='CustomDropdown__selectedOption'] {
      border-right: solid 1px #ccc;
      margin: 0;
    }

    //SB-TODO - double underscore > single underscore
    [class*='CustomDropdown_selectedOption'] {
      border-right: solid 1px #ccc;
      margin: 0;
    }

    [class*='CustomDropdown__chevronIcon'] {
      justify-self: center;
      margin: 0;
    }

    //SB-TODO - double underscore > single underscore
    [class*='CustomDropdown_chevronIcon'] {
      justify-self: center;
      margin: 0;
    }

    @include sb-respond-to(small) {
      padding: 0 $sb-spacing-m 0 0;
      border: none;
      height: 80px;
      border-radius: 0;

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        top: calc(50% - 12px);
        height: 24px;
        width: 1px;
        background-color: $sb-colour-background-inverse;
      }

      [class*='CustomDropdown__selectedOption'] {
        padding: 0;
        margin-left: $sb-spacing-m;
        margin-right: $sb-spacing-m;
        border: none;
      }

      //SB-TODO - double underscore > single underscore
      [class*='CustomDropdown_selectedOption'] {
        padding: 0;
        margin-left: $sb-spacing-m;
        margin-right: $sb-spacing-m;
        border: none;
      }
    }
  }
}

.optionsContainer {
  z-index: 2;
  border-top: $sb-border-width-s solid $sb-colour-border-default;

  // styles to position dropdown inline with menu and make it the same width
  @include sb-respond-to(medium-up) {
    width: $max-width;
    border-top: none;
  }
}
