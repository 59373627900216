@import '~@which/seatbelt/src/styles/styles';

.panel {
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $sb-colour-background-default;
  padding: $sb-spacing-m $sb-spacing-l $sb-spacing-l;
  box-shadow: 2px 6px 18px -2px sb-color('sb-color-black-100');
  z-index: sb-z-index('sb-z-index-1');

  &::after {
    $height: 3px;
    content: '';
    position: absolute;
    width: 100%;
    height: $height;
    background-color: $sb-colour-background-default;
    left: 0;
    right: 0;
    top: -$height;
  }

  @include sb-respond-to(medium-up) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: $sb-spacing-l;
  }

  @include sb-respond-to(large) {
    display: none;
  }

  &ButtonWrapper {
    display: flex;
    justify-content: center;
  }

  &Button {
    width: 120px;
    justify-content: center;

    &:first-of-type {
      margin-right: $sb-spacing-l;
      @include sb-respond-to(medium-up) {
        margin-left: auto;
      }
    }
  }

  &Results {
    margin-bottom: $sb-spacing-m;

    @include sb-respond-to(medium-up) {
      margin-bottom: 0;
    }
  }
}
